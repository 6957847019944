.App {
  position: relative;
  min-height: 100vh;
  background-color: #fff;
}

.container {
  background-color: #fff;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

img {
  max-width: 100%;
}

.logo,
.img-flexivel {
  margin-top: auto;
}

.logo img {
  height: 20vmin;
}

@media (max-width: 640px) {
  .logo img {
    height: auto;
    width: 100%;
  }
}

a {
  text-decoration: none;
}

hr {
  height: 1px;
  border: 0;
  background-color: #313A48;
  color: #313A48;
}

html {
  scroll-behavior: smooth;
}
